.input-criteria {
  margin: 5px 0 0 3px;
}

.password-criteria div {
  cursor: pointer;
}

.password-criteria li {
  list-style: none;
  margin-left: 10px;
}
