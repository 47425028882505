.new-cube-btn {
  background-color: var(--primary-btn-color);
  font-size: 16px;
  height: 54px;
  margin: 15px;
  min-width: 312px;
  padding: 5px 10px;
  white-space: nowrap;
}

.new-cube-btn:hover {
  background-color: var(--primary-btn-color-hover);
}

@media screen and (max-width: 720px) {
  .new-cube-btn {
    height: 32px;
    margin: 0;
    min-width: auto;
  }
}
