@media screen and (max-width: 720px) {
  .navbar-mobile {
    background-color: var(--secondary-bg-color);
    border-top: 4px solid var(--accent-color);
    -webkit-box-shadow: 10px 6px 4px -7px var(--box-shadow-color);
    box-shadow: 10px 6px 4px -7px var(--box-shadow-color);
    color: var(--text-color-light);
    font-weight: 700;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    min-height: 70px;
    padding: 0 20px;
    width: 100%;
    z-index: 6;
  }

  .mobile-navbar-item:not(.selected, [alt="Create Cube"], button) {
    color: var(--text-color-light) !important;
  }

  .mobile-navbar-item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
    border-radius: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 12px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    margin: 0 5px;
    min-width: calc(100% / 4);
    padding: 4px 16px;
    white-space: nowrap;
  }

  .mobile-navbar-item.selected {
    color: var(--mobile-nav-item-select);
  }

  .mobile-navbar-item svg {
    margin-bottom: 2px;
  }

  .divider-line {
    background-color: var(--secondary-bg-color);
    height: 1px;
    width: 100%;
  }
}

@media screen and (max-width: 280px) {
  .mobile-navbar-item {
    font-size: 8px;
  }
}
