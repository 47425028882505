.list-footer {
  padding: 15px;
}

.list-footer {
  background-color: var(--primary-bg-color);
  border-right: 1px solid var(--category-select);
  color: var(--text-color-light);
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 14px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  white-space: nowrap;
}

.list-footer p {
  margin-bottom: 0;
}

.list-footer a {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

/* ------------------- Media Queries ------------------- */

@media screen and (max-width: 720px) {
  .list-footer {
    display: none !important;
  }
}
