.confirmation-modal .modal-footer {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.confirmation-modal .modal-title {
  text-align: left;
}

@media screen and (max-width: 720px) {
  .confirmation-modal.modal {
    z-index: 20;
  }
}
