.splash {
  height: 100vh;
  height: 100svh;
}

.section {
  color: var(--primary-text-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.section-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 1440px;
  padding: 60px;
}

.section-content p {
  font-size: 20px;
}

.section-content h3:not(.bounce h3) {
  margin-bottom: 0;
}

.cube-scene {
  background: -o-linear-gradient(left, rgba(0, 0, 0, 0.7), rgba(255, 0, 0, 0));
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.7)),
    to(rgba(255, 0, 0, 0))
  );
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(255, 0, 0, 0));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 60%;
  position: relative;
}

.cube-scene .copy-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-width: 1440px;
  pointer-events: none;
  position: absolute;
  width: 100%;
  z-index: 5;
}

.headline {
  color: var(--text-color-light);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 40px 40px 40px 100px;
  max-width: 600px;
}

.brand-name {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 10px;
}

.tagline {
  font-size: 26px;
  line-height: 40px;
}

.fun-fact {
  background-color: var(--primary-bg-color);
  border: 2px solid var(--accent-color);
  border-radius: 3px;
  bottom: -120px;
  color: var(--text-color-light);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 300px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px;
  right: 60px;
  text-align: center;
  width: 300px;
}

.fun-fact {
  font-size: 20px;
  position: absolute;
}

.fun-fact hr {
  margin: 0.5rem 0;
}

.learn-more {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 8px solid var(--nav-bg-color);
  height: 40%;
}

.learn-more .section-content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.learn-more,
.about-app,
.contact {
  background-color: var(--category-select);
}

.celebrate,
.about-developer {
  background-color: var(--nav-bg-color);
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

.section-content h3:not(.bounce h3) {
  font-size: 28px;
}

.bounce {
  opacity: 1;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
  -webkit-transition: opacity 0.25s ease-in;
  -o-transition: opacity 0.25s ease-in;
  transition: opacity 0.25s ease-in;
  text-align: center;
}

.bounce h3 {
  font-size: 26px;
}

.bounce .bounce.hide {
  -webkit-animation: none;
  animation: none;
}

.learn-more .sign-up-btn,
.start-studying .sign-up-btn {
  border-radius: 50px;
  font-size: 16px;
  padding: 20px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.learn-more .sign-up-btn {
  margin-right: 350px;
  margin-left: 20px;
}

.learn-more .sign-up-btn:hover {
  background-color: var(--primary-btn-color-hover) !important;
}

.sign-up-cta {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.sign-up-cta h3 {
  margin-bottom: 0;
}

.about-app img {
  border: 2px solid var(--accent-color);
  border-radius: 3px;
  height: auto;
  width: 70%;
}

.about-app .copy-container {
  margin-left: 40px;
}

.start-studying {
  background: var(--stars-bg-img);
  background-size: contain;
}

.start-studying .section-content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--text-color-light);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.about-developer .section-content {
  background-color: var(--nav-bg-color);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.contact .section-content {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.contact .copy-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  gap: 60px;
}

.contact .copy-container div:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.contact-info {
  min-width: 270px;
}

.contact-info hr {
  background: var(--primary-text-color);
}

.contact-info p:nth-child(2) {
  margin-bottom: 0;
}

.contact a {
  color: var(--social-link-color);
}

.warning {
  color: var(--accent-color);
  background-color: var(--primary-bg-color);
  border: 1px solid var(--accent-color);
  border-left-width: 6px;
  padding: 0 20px;
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px !important;
  gap: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.warning span:first-of-type:not(.toggle-info) {
  font-size: 14px !important;
  margin-bottom: 0;
  border-left: 1px solid var(--accent-color);
  padding: 10px;
}

.warning-more-info {
  border-left: none;
  display: block;
  padding: 0 10px;
  margin: 10px;
}

.warning span {
  display: block;
}

/* ---------------------- Media Queries -------------------- */

@media screen and (max-width: 1325px) {
  .section-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 100vw;
    padding: 60px;
  }

  .section-content h3:not(.bounce h3) {
    margin-bottom: 20px;
  }

  .learn-more .section-content {
    height: 100%;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    max-width: 300px;
    padding: 8vh 0 20px;
    text-align: center;
    white-space: normal;
  }

  .learn-more h3 {
    font-size: 24px;
  }

  .headline {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-top: 40px;
    padding: 40px 40px 40px 60px;
  }

  .tagline {
    font-size: 22px;
  }

  .fun-fact {
    bottom: -40px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    padding: 30px;
    width: 400px;
  }

  .sign-up-cta {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .sign-up-cta h3 {
    margin-bottom: 10px;
  }

  .bounce h3 {
    font-size: 20px;
  }

  .about-app img {
    width: 100%;
  }

  .about-app .copy-container {
    margin-left: 0;
    margin-top: 40px;
  }
  .learn-more .sign-up-btn,
  .start-studying .sign-up-btn {
    margin: 0;
    font-size: 16px;
  }
}

@media screen and (max-width: 720px) {
  .splash.mobile-nav-is-shown {
    height: calc(100vh - 60px);
    height: calc(100svh - 60px);
  }

  .section-content {
    padding: 40px 20px;
  }

  .section-content h3:not(.bounce h3) {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    max-width: 300px;
  }

  .section-content p {
    font-size: 16px;
  }

  .cube-scene.mobile-nav-is-shown {
    height: 65%;
  }

  .learn-more.mobile-nav-is-shown {
    height: 35%;
  }

  .learn-more .section-content {
    max-width: 240px;
  }

  .learn-more h3 {
    font-size: 16px;
    text-align: center;
    white-space: normal;
  }

  .headline {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-top: 40px;
    max-width: 325px;
    padding: 40px 30px;
  }

  .brand-name {
    font-size: 36px;
  }

  .tagline {
    font-size: 16px;
    line-height: 30px;
  }

  .fun-fact {
    bottom: -30px;
    font-size: 14px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    padding: 10px;
    left: 0;
    right: 0;
    margin: auto;
    width: 260px;
  }

  .learn-more .sign-up-btn,
  .start-studying .sign-up-btn {
    margin: 0;
    font-size: 14px;
    height: 30px;
    padding: 18px 15px;
  }

  .sign-up-cta {
    margin-bottom: 20px;
  }

  .bounce h3 {
    font-size: 14px;
  }

  .contact.mobile-nav-is-shown .section-content {
    padding-bottom: 80px;
  }

  .contact .copy-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
  }

  .warning.desktop-hidden {
    margin: 30px 0 20px;
  }

  .copyright.desktop-hidden {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 280px) {
  .brand-name {
    font-size: 32px;
  }

  .tagline {
    font-size: 16px;
    line-height: 24px;
  }

  .tagline,
  .fun-fact {
    font-size: 12px;
  }

  .fun-fact {
    padding: 10px;
  }

  .cube-scene .fun-fact {
    width: 220px;
  }

  .section-content h3:not(.bounce h3) {
    font-weight: normal;
  }
}
