.cube-page-container {
  height: 100%;
  width: 100%;
}

.cube-container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-perspective: 560vmin;
  perspective: 560vmin;
  width: 68vmin;
}

.cube-ctrl-group {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--secondary-bg-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  z-index: 5;
}

.cube-area-container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 5vmin;
  position: relative;
}

.guide-background {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  pointer-events: none;
  position: absolute;
  z-index: 4;
}

.cube-radio-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10vh;
}

/* ----------------------------------------- Study Cube */

.study-cube {
  padding-bottom: 100%;
  rotate: 0 0 0 0turn;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: rotate 1s;
  -o-transition: rotate 1s;
  transition: rotate 1s;
  width: 100%;
  will-change: rotate;
}

/* Rotation need to rotate entire cube to a certain face, from the original cube position */
.study-cube.Question {
  rotate: y 0turn;
}
.study-cube.Answer {
  rotate: y 0.5turn;
}
.study-cube.Visual {
  rotate: x -0.25turn;
}
.study-cube.Links {
  rotate: x -0.75turn;
}
.study-cube.Notes {
  rotate: y 0.25turn;
}
.study-cube.Hint {
  rotate: y 0.75turn;
}

/* ----------------------------------------------- Cube Faces */

.face {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid var(--nav-btn-bg-color);
  color: var(--primary-text-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 1;
  position: absolute;
  text-align: center;
  width: 100%;
}

.face-title {
  border-bottom: 1px solid var(--primary-text-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 6vmin;
  font-weight: bold;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  line-height: 1.4;
  margin: 0 0 40px;
}

.face-group {
  -webkit-filter: blur(0);
  filter: blur(0);
  height: 100%;
  padding: 60px;
  transition: -webkit-filter 1s;
  -webkit-transition: -webkit-filter 1s;
  -o-transition: filter 1s;
  transition: filter 1s;
  transition: filter 1s, -webkit-filter 1s;
  width: 100%;
  will-change: filter;
}

.face-content {
  font-size: clamp(1rem, 3.1vmin, 2rem);
  max-height: 70%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  text-align: left;
  white-space: pre-line;
  width: 100%;
  word-break: break-word;
}

/* Position of faces when moved away from origin to form cube */
.face.Question {
  background-color: var(--cube-face-color-1);
  rotate: y 0turn;
  -webkit-transform: translateZ(34vmin);
  transform: translateZ(34vmin);
}
.face.Answer {
  background-color: var(--cube-face-color-1);
  rotate: y 0.5turn;
  -webkit-transform: translateZ(34vmin);
  transform: translateZ(34vmin);
}
.face.Visual {
  background-color: var(--cube-face-color-2);
  rotate: x 0.25turn;
  -webkit-transform: translateZ(34vmin);
  transform: translateZ(34vmin);
}
.face.Links {
  background-color: var(--cube-face-color-2);
  rotate: x -0.25turn;
  -webkit-transform: translateZ(34vmin);
  transform: translateZ(34vmin);
}
.face.Notes {
  background-color: var(--cube-face-color-3);
  rotate: y -0.25turn;
  -webkit-transform: translateZ(34vmin);
  transform: translateZ(34vmin);
}

.face.Hint {
  background-color: var(--cube-face-color-3);
  rotate: y 0.25turn;
  -webkit-transform: translateZ(34vmin);
  transform: translateZ(34vmin);
}

ul.face-content {
  list-style: none;
}

.face-content a {
  color: var(--face-cube-text-color);
  text-decoration: underline;
}

.face-content a:hover {
  text-decoration: none;
}

.Visual .face-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.blur {
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

.face-not-focused {
  overflow: hidden;
}

/* ---------------------------------------------- Visual Aid Face */

.visual-aid {
  cursor: pointer;
  -o-object-fit: contain;
  object-fit: contain;
  width: inherit;
}

/* ----------------------------------- Cube Face Radio Btns */

.cube-face-list {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 8px solid var(--nav-bg-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 93px;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.radio-face-group {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.radio-face-group .radio-label {
  white-space: nowrap;
}

@media screen and (max-width: 720px) {
  .cube-ctrl-group {
    position: absolute;
  }

  .cube-area-container {
    margin: calc(5vmin + 41px) 5vmin 0;
    padding-bottom: 5vmin;
  }

  .cube-face-list {
    height: auto;
    width: 100%;
  }

  .radio-face-group .radio-label {
    font-size: 12px;
    padding: 5px;
    border-bottom: 2px solid transparent;
  }

  .radio-face-group .radio-button {
    padding: 14px 0 0;
  }

  .radio-face-group input[type="radio"]:checked + label {
    border-bottom: 2px solid var(--accent-color);
  }

  .face-group {
    padding: 20px;
  }

  .face-title {
    margin: 0 0 20px;
  }

  .blur {
    -webkit-filter: blur(3px);
    filter: blur(3px);
  }
}

@media screen and (max-width: 280px) {
  .radio-face-group .radio-label {
    font-size: 8px;
  }
}
