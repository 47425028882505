.navbar {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--nav-bg-color);
  color: var(--text-color-light);
  font-weight: bold;
  -ms-flex-preferred-size: 50px;
  min-height: 60px;
  padding: 0 5px;
  width: 100%;
  z-index: 6;
}

.navlinks-container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 600px;
}

.btn.navbar-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border-radius: 50px;
  color: var(--primary-text-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  height: 42px;
  margin: 0 5px;
  padding: 20px;
}

.btn.navbar-item.selected {
  color: var(--nav-item-select);
}

.navbar-item.logout-btn {
  margin: 0;
  width: 100%;
}

.navbar-item.username {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 5px;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0 16px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navbar-item.username svg {
  margin-top: 1px;
}

.auth-btns-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.auth-btns-container div {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 20px 55px 0 0;
  max-width: 1440px;
  width: 100%;
}

/* ------------------------------- Dropdown Menu Item */

.dropdown {
  border-left: 1px solid var(--category-select);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 11px;
  padding: 0 5px 0 10px;
}

.dropdown-content {
  background-color: var(--nav-bg-color);
  border: 1px solid var(--category-select);
  border-right: none;
  -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  margin-top: 60px;
  min-width: 160px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.dropdown-content > * {
  padding: 10px;
}

.dropdown-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--primary-text-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 16px;
}

.dropdown-item:not(:last-of-type) {
  border-bottom: 1px solid var(--category-select);
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* ------------- Media Queries -------------- */

@media screen and (max-width: 720px) {
  .navbar:not(.navbar-mobile) {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    min-height: 50px;
  }

  .navbar-item {
    font-size: 14px;
    padding: 8px 12px;
  }

  .btn.navbar-item {
    font-size: 14px;
    height: 30px;
    padding: 18px 15px;
  }

  .navbar-item.about-dash {
    width: 167px;
  }

  .navbar-item.username {
    padding: 0 5px 0 10px;
  }

  .about-settings {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    width: 100%;
  }

  .dropdown {
    border: none;
    margin: 0;
    padding: 0;
  }

  .dropdown-content {
    margin-top: 50px;
  }

  .auth-btns-container div {
    padding: 10px 5px 0 0;
  }
}
