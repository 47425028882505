.modal-guide {
  -webkit-animation: 250ms ease-out 0s 1 normal none running fadeIn;
  animation: 250ms ease-out 0s 1 normal none running fadeIn;
  background-clip: padding-box;
  background-color: var(--modal-background-color);
  border: 1px solid var(--modal-border-color);
  border-radius: 0.3rem;
  color: var(--text-color-light);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
  left: 0;
  max-width: 400px;
  padding: 16px;
  position: absolute;
  top: 0;
  -webkit-transform-origin: center top;
  -ms-transform-origin: center top;
  transform-origin: center top;
  white-space: normal;
  z-index: 6;
}
.modal-guide:before {
  border-color: transparent var(--modal-border-color) transparent transparent;
  border-style: solid;
  border-width: 20px;
  content: "";
  position: absolute;
}

.modal-guide.tour-step-1 {
  left: auto;
}
.modal-guide.tour-step-1:before {
  display: none;
}

.modal-guide.tour-step-2:before,
.modal-guide.tour-step-3:before {
  left: 0;
  top: 20px;
  -webkit-transform: translate(-100%);
  -ms-transform: translate(-100%);
  transform: translate(-100%);
}

.modal-guide.tour-step-4:before {
  right: 20px;
  top: 0;
  -webkit-transform: translatey(-100%) rotate(90deg);
  -ms-transform: translatey(-100%) rotate(90deg);
  transform: translatey(-100%) rotate(90deg);
}

.modal-guide .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.modal-guide h1 {
  font-size: 1.8rem;
}

.modal-guide-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 16px;
}

.modal-guide .modal-footer {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.modal-guide.tour-step-4 .modal-footer {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.modal-guide [title="Back"] {
  height: 36px;
  padding: 8px 16px;
}

.modal-guide hr {
  margin: 0;
}

.modal-guide ul {
  margin-left: 30px;
}

.modal-guide li {
  list-style: circle;
}

.modal-guide label {
  font-size: 12px;
  line-height: 1.2;
  margin-left: 10px;
}

.modal-guide form {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.modal-guide input[type="checkbox"] {
  accent-color: var(--accent-color);
}

.modal-guide.return-user.tour-step-1 .modal-footer {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.modal-guide.return-user .subtext {
  max-width: 50%;
}

@media screen and (max-width: 720px) {
  .modal-guide {
    display: block;
    line-height: 1.3;
    padding: 0;
  }

  .modal-dialog {
    min-width: 80%;
  }

  .modal-guide .modal-header,
  .modal-guide-body,
  .modal-guide .modal-footer {
    padding: 0.5rem 1rem;
  }
  .modal-guide.tour-step-1 {
    bottom: auto;
    top: auto;
  }
  .modal-guide.tour-step-2,
  .modal-guide.tour-step-3 {
    bottom: 0;
    margin-bottom: 30px;
    top: auto;
  }
  .modal-guide.tour-step-4 {
    bottom: auto;
    margin-top: 10px;
    top: 0;
  }

  .modal-guide.tour-step-2:before,
  .modal-guide.tour-step-3:before {
    bottom: 0;
    left: 3px;
    top: auto;
    -webkit-transform: translatey(100%) rotate(-90deg);
    -ms-transform: translatey(100%) rotate(-90deg);
    transform: translatey(100%) rotate(-90deg);
  }

  .modal-guide.tour-step-3:before {
    left: 105px;
  }

  .modal-guide.return-user .subtext {
    margin-bottom: 5px;
  }

  .modal-guide .modal-footer-buttons {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .modal-guide button:not(.close),
  .modal-guide [type="button"]:not(.close) {
    font-size: 12px;
    padding: 8px 12px;
  }

  .modal-guide button[title="Back"] {
    height: 32px;
  }
}

@media screen and (max-width: 280px) {
  .modal-guide .modal-footer {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: center;
  }
  .modal-guide form,
  .modal-guide .subtext {
    -ms-flex-item-align: start;
    align-self: flex-start;
    margin-bottom: 20px;
  }

  .modal-guide.return-user.tour-step-1 .modal-footer {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .modal-guide.tour-step-4 .modal-footer form {
    margin: 0 0 6px;
  }

  .modal-guide.tour-step-4 .modal-footer label {
    font-size: 10px;
    margin-left: 5px;
  }

  .modal-guide.return-user .subtext {
    margin-bottom: 5px;
    max-width: 50%;
  }
}
